<template>
  <registered-person
    :user="getUSER"
    :timer="timer"
    :showChecking="showChecking"
    :accounts-list="getBANK_ACCOUNTS"
    :fund-name="fond ? fond.name : null"
    :account="draft ? draft.bank_account_id : null"
    :ownership="ownership"
    :shared-count="draft ? draft.shared_count : null"
    :promise-change="draft ? draft.promise_change_data : null"
    :promise-update="draft ? draft.promise_update_data : null"
    :disabled="timer"
    @submit="onSibmit"
    @sendAgain="onSibmit"
    @verified="onVerifiedCode"
    @update="onUpdate"
    @timer="timerIsOver"
    @addBankAccount="addBankAccount"
  />
</template>

<script>
import RegisteredPerson from "../../../../components/pages/cabinet/documents/forms/RegisteredPerson";
import { mapActions, mapGetters } from "vuex";
import AddBankAccount from "../../../../components/modals/AddBankAccount";
import moment from "moment";
import RWMSendingDocumentsMessageMixin from "../../../../components/mixins/RWMSendingDocumentsMessageMixin";

const model = {
  fond_id: null, //ID фонда
  bank_account_id: null, //ID банковских реквизитов
  ownership_collective: null, //Вид собственности на инвестиционные паи: совместная
  ownership_common_shared: null, //Вид собственности на инвестиционные паи: Общая долевая
  shared_count: "", //Доля
  promise_change_data: null, //Обязуюсь при изменении любых сведений, указанных в данной Анкете, предоставлять обновленные данные
  promise_update_data: null, //Обязуюсь обновлять сведения, указанные в данной Анкете, не реже 1 раза в год
};

export default {
  name: "FormRegisteredPerson",
  components: { RegisteredPerson },
  mixins: [RWMSendingDocumentsMessageMixin],
  computed: {
    ...mapGetters("errorModule", ["getERROR"]),
    ...mapGetters("AuthModule", ["getUSER"]),
    ...mapGetters("DocumentsModule", ["getDRAFT"]),
    ...mapGetters("bankAccModule", ["getBANK_ACCOUNTS"]),
    draft() {
      return this.getDRAFT?.data;
    },
    fond() {
      return this.getDRAFT?.fond;
    },
    ownership() {
      return true
      // return this.getDRAFT?.data
      //   ? this.getDRAFT.data.ownership_collective
      //     ? true
      //     : this.getDRAFT.data.ownership_common_shared
      //     ? false
      //     : false
      //   : false;
    },
  },
  data() {
    return {
      loading: false,
      timer: false,
      showChecking: false,
    };
  },
  methods: {
    ...mapActions("smsModule", ["REQUEST_SMS", "CONFIRM_SMS"]),
    ...mapActions("DocumentsModule", [
      "CREATE_DOCUMENT",
      "UPDATE_DOCUMENT",
      "GET_DOCUMENTS",
    ]),
    ...mapActions("bankAccModule", ["GET_BANK_ACCOUNTS"]),
    createDocument() {
      // looking fot exist document & if its not, creating new one
      let fundId = this.$route.query.to;
      if (!fundId) {
        this.$router.push("/cabinet/funds");
      }
      this.GET_DOCUMENTS({ alias: "qst-reg_person", fundId: fundId }).then(
        (resp) => {
          if (resp) {
            if (!this.getDRAFT) {
              model.fond_id = fundId;
              model.ownership_collective = false;
              model.ownership_common_shared = true;
              model.bank_account_id = this.getBANK_ACCOUNTS?.[0]?.id ?? null;
              this.CREATE_DOCUMENT({
                alias: "qst-reg_person",
                data: model,
              }).then((response) =>
                !response
                  ? this.$toast.error(`${this.getERROR}`)
                  : this.GET_DOCUMENTS({
                      alias: "qst-reg_person",
                      fundId: fundId,
                    })
              );
            }
          }
        }
      );
    },
    onUpdate(value) {
      const mode = {
        fund: "fond_id", //ID фонда
        account: "bank_account_id", //ID банковских реквизитов
        collective: "ownership_collective", //Вид собственности на инвестиционные паи: совместная
        shared: "ownership_common_shared", //Вид собственности на инвестиционные паи: Общая долевая
        count: "shared_count", //Доля
        promise_change: "promise_change_data", //Обязуюсь при изменении любых сведений, указанных в данной Анкете, предоставлять обновленные данные
        promise_update: "promise_update_data", //Обязуюсь обновлять сведения, указанные в данной Анкете, не реже 1 раза в год
      };
      if (this.getDRAFT) {
        let data = {};
        switch (value.property) {
          case "collective":
            data.ownership_common_shared = !value.property;
            break;
          case "shared":
            data.ownership_collective = !value.property;
            break;
        }
        data[mode[value.property]] =
          typeof value.value === "object" ? value.value.id : value.value;
        this.UPDATE_DOCUMENT({ id: this.getDRAFT.id, data: data });
        this.showChecking = false;
      }
    },
    onSibmit() {
      this.loading = true;
      this.REQUEST_SMS({ phone: this.getUSER.email, doc_id: this.getDRAFT ? this.getDRAFT.id : null, action: 'document_sign' }).then((success) => {
        if (success) {
          this.showChecking = true;
          this.timer = true;
          this.loading = false;
        } else {
          this.$toast.error(`${this.getERROR}`);
        }
      });
    },
    onVerifiedCode(code) {
      this.CONFIRM_SMS({ phone: this.getUSER.email, key: code }).then(
        (success) => {
          if (success) {
            let data = { ...this.getDRAFT.data };
            data.date_sign = moment(new Date()).format("X");
            data.signed = true;

            this.UPDATE_DOCUMENT({ id: this.getDRAFT.id, data: data });

            this.show_sending_documents_message();
            setTimeout(() => {
              if (typeof this.$route.query.next !== "undefined" && this.$route.query.next !== "")
                this.$router.push("/cabinet/investment/purchase-of-investment-shares/" + this.$route.query.next + "?next")
              else {
                let id = this.$route.query.to;
                this.$router.push(
                        `/cabinet/investment/purchase-of-investment-shares/${id}`
                );
              }
            }, 1000);
          } else {
            this.$toast.error(`${this.getERROR}`);
          }
        }
      );
    },
    timerIsOver(timer) {
      this.timer = timer;
    },
    addBankAccount(params = {}) {
      this.$modal.show(
        AddBankAccount,
        {
          params,
        },
        {
          width: 522,
          height: "auto",
          clickToClose: false,
          scrollable: true,
          name: "AddBankAccount",
        },
        {
          "before-open": this.beforeOpen,
          "before-close": this.beforeClose,
          // 'closed': setTimeout(() => {this.$router.push('/login')}, 1000)
        }
      );
    },
    beforeOpen() {
      let body = document.body;
      body.classList.add("modal-open");
    },
    beforeClose() {
      let body = document.body;
      body.classList.remove("modal-open");
    },
  },
  mounted() {
    this.createDocument();
    this.GET_BANK_ACCOUNTS();
  },
};
</script>
