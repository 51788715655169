<template>
    <div>
        <div class="form">
            <h1>Анкета зарегистрированного физического лица</h1>
            <div class="form__body">
                <div>
                    <h3>
                        {{fundName}}
                    </h3>
                    <h3>
                        Управляющая компания: Закрытое Акционерное Общество Управляющая компания «РВМ Капитал»
                    </h3>
                </div>
                <client-block :mname="user.userName.mname" :name="user.userName.name" :sname="user.userName.sname"/>
                <passport-block :department="user.passport.department" :issue="user.passport.issue"
                                :issue-date="user.passport.issueDate"
                                :number="user.passport.number"/>
                <address-block
                        :actual-address="user.actualAddress"
                        :birthday="user.birthday"
                        :birthplace="user.birthplace"
                        :email="user.email"
                        :inn="user.inn"
                        :phone="user.phone"
                        :registration-address="user.registrationAddress"
                        :snils="user.snils.number"
                />

                <div class="row">
                    <div class="co-12">
                        <p class="anketa">
                            Сведения о реквизитах для выплаты доходов:
                        </p>
                    </div>
                    <div class="col-9">
                        <rwm-select
                                id="BankAccountSelect"
                                :tooltip-text="typeof accountsList == 'undefined' || accountsList.length == 0? 'У Вас нет банковских реквизитов, заполните, пожалуйста, по кнопке \'Добавить новые реквизиты\'' : null"
                                :default-value="bankAccount"
                                default-property="id"
                                placeholder="Выберите банковские реквизиты"
                                :list="accountsList ? accountsList : []"
                                @selected="update('account', ...arguments)"
                                :disabled="disabled || typeof accountsList == 'undefined' || accountsList.length == 0"
                                :error="errors.account"
                        />
                    </div>
                    <div class="col-3">
                        <rwm-button width="100%" outlined @click="$emit('addBankAccount')" :disabled="disabled">
                            <v-icon :hovering="false" size="28px" style="margin-right: 14px">plus</v-icon>
                            Добавить новые реквизиты
                        </rwm-button>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12">
                        <p class="anketa">Вид собственности на инвестиционные паи (для Зарегистрированных лиц-собственников):</p>
                    </div>
                    <div class="col-6">
                        <div class="group-selecting">
                            <rwm-radiobox :value="true" label="Совместная"
                                          name="ownership"
                                          @change="update('collective', true)"
                                          :checked="ownership"
                                          disabled
                                          readonly
                                          :error="errors.ownership"
                            />
                            <rwm-radiobox :value="false" label="Общая долевая"
                                           name="ownership"
                                          :checked="ownership !== null ? !ownership : ownership"
                                          @change="update('shared', true)"
                                          disabled
                                          :error="errors.ownership"
                            />

                        </div>
                    </div>
                    <div class="col-6">
                        <rwm-text-field
                                v-model="_TypeOfSharesOwnership"
                                :disabled="ownership || disabled"
                                @blur="update('count', TypeOfSharesOwnership)"
                                :error="errors.sharedCount"
                        />
<!--                        :error="errors.sourcesOfFunds"-->
<!--                        @focus="errors.sourcesOfFunds = false"-->
<!--                        @blur="onSomeChange('sourcesOfFunds', ...arguments)"-->

                    </div>
                </div>

<!--                <div class="row">-->
<!--                    <rwm-checkbox-->
<!--                            label="Обязуюсь при изменении любых сведений, указанных в данной Анкете, предоставлять обновленные данные в течение 5 рабочих дней."-->
<!--                            :checked="promiseChange"-->
<!--                            @change="update('promise_change', ...arguments)"-->
<!--                            :disabled="disabled"-->
<!--                            :error="errors.promiseChange"-->
<!--                    />-->
<!--                </div>-->

<!--                <div class="row">-->
<!--                    <rwm-checkbox-->
<!--                            label="Обязуюсь обновлять сведения, указанные в данной Анкете, не реже 1 раза в год путем предоставления новой анкеты. В случае не предоставления новой анкеты в течение года, прошу сведения считать обновленными."-->
<!--                            :checked="promiseUpdate"-->
<!--                            @change="update('promise_update', ...arguments)"-->
<!--                            :disabled="disabled"-->
<!--                            :error="errors.promiseUpdate"-->
<!--                    />-->
<!--                </div>-->

                <div class="row">
                    <div class="col-3">
                        <rwm-button width="100%" @click="submit" :disabled="!readyToSubscribe">Подписать</rwm-button>
                    </div>
                </div>
                <rwm-sms-checking v-model="_timer" :isShow="showChecking" :time="60" @sendAgain="sendAgain()"
                                  @verified="codeIsVerified"/>
            </div>
        </div>
    </div>
</template>

<script>
    import ClientBlock from "./blocks/ClientBlock";
    import PassportBlock from "./blocks/PassportBlock";
    import AddressBlock from "./blocks/AddressBlock";
    import RwmButton from "../../../../RWMButton/RwmButton";
    import RwmSmsChecking from "../../../../RWMSMSChecking/RwmSmsChecking";
    import RwmSelect from "../../../../RWMSelect/RwmSelect";
    import VIcon from "../../../../icons/VIcon";
    import RwmTextField from "../../../../RWMTextField/RwmTextField";
    import RwmRadiobox from "../../../../RWMRadioBox/RwmRadioBox";
    // import RwmCheckbox from "../../../../RWMCheckbox/RwmCheckbox";

    export default {
        name: "RegisteredPerson",
        components: {
            // RwmCheckbox,
            RwmRadiobox,
            RwmTextField, VIcon, RwmSelect, RwmSmsChecking, RwmButton, AddressBlock, PassportBlock, ClientBlock},
        props: {
            user: Object,
            timer: {
                type: Boolean,
                default: false
            },
            disabled: {
                type: Boolean,
                default: false
            },
            showChecking: {
                type: Boolean,
                default: false
            },
            accountsList: {
                type: Array,
                required: true
            },
            account: [String, Number],
            ownership: {
                type: Boolean,
                default: true
            },
            sharedCount: String,
            // promiseChange: Boolean,
            // promiseUpdate: Boolean,
            fundName: String
        },
        computed: {
            _timer: {
                get() {
                    return this.timer
                },
                set(val) {
                    this.$emit('timer', val)
                    this.readyToSubscribe = !val
                }
            },
            bankAccount() {
                return this.accountsList && this.accountsList.length > 0 && this.account
                    ? this.accountsList.find(el => el.bank_account_id === this.account)
                      && this.accountsList.find(el => el.bank_account_id === this.account).length > 0
                        ? this.accountsList.find(el => el.bank_account_id === this.account)[0].name
                        : this.accountsList[0].name
                    : ""
            },
            _TypeOfSharesOwnership: {
                get() {
                    return this.sharedCount
                },
                set(val) {
                    this.TypeOfSharesOwnership = val
                }
            }
        },
        data() {
            return {
                readyToSubscribe: true,
                TypeOfSharesOwnership: "",
                showTooltip: false,
                errors: {
                    account: false,
                    ownership: false,
                    sharedCount: false,
                    // promiseChange: false,
                    // promiseUpdate: false
                }
            }
        },
        methods: {
            update(property, value) {
                this.$emit('update', {property: property, value: value})
            },
            sendAgain() {
                this.$emit('sendAgain')
            },
            codeIsVerified(code) {
                this.$emit('verified', code)
            },
            validate() {
                this.errors.account = !this.bankAccount && this.bankAccount === ""
                this.errors.ownership = !typeof (this.ownership) === "boolean"
                this.errors.sharedCount = !this.sharedCount && this.sharedCount !== ""
                // this.errors.promiseChange = !(typeof (this.promiseChange) === "boolean" && this.promiseChange)
                // this.errors.promiseUpdate = !(typeof (this.promiseUpdate) === "boolean" && this.promiseUpdate)

                let result = true

                for (let error in this.errors) {
                    if (this.errors[error]) {
                        this.$toast.error('Все поля обязательны для заполнения')
                        result = false
                        break
                    }
                }
                return result
            },
            submit() {
                if (this.validate()) {
                    this.readyToSubscribe = false
                    this.$emit('submit')
                }
            },
        }
    }
</script>

<style scoped lang="scss">
    .form {
        text-align: left;

        & h1 {
            margin-bottom: 32px;
        }

        &__body {
            display: flex;
            flex-direction: column;
            row-gap: 32px;

            & .block {
                display: flex;
                flex-direction: column;
                row-gap: 24px;

                &:after {
                    content: "";
                    opacity: 0.5;
                    border: 1px solid $RWM-grey-light;
                }

                &:last-child {
                    &:after {
                        border: none;
                    }
                }
            }

            & .group-selecting {
                display: flex;
                flex-wrap: wrap;
                justify-content: flex-start;
                column-gap: 44px;
                row-gap: 24px;
                @media screen and (min-width: 1024px) {
                    flex-wrap: nowrap;
                    justify-content: initial;
                    align-items: center;
                }

                & .rwm-text-field {
                    flex: 1 1 auto;
                }
            }
        }
    }
</style>
